#root.lims {

    .addLocation::before,
    .addLocation::after {
        color: $lims-bgdark;
    }

    .custom-check input:checked~.checkmark,
    .custom-radio input:checked~.radiomark {
        background-color: $lims-bgdark;
        border: 1px solid $lims-bgdark;
    }

    .css-26l3qy-menu {

        .css-9gakcf-option {
            background: $lims-bgdark !important;
        }

        .css-1n7v3ny-option {
            background: tint($lims-bgdark, 90%) !important;
        }
    }

    .accordion {
        background: tint($lims-bgdark, 90%) !important;
    }

    .accordion-panel,
    .custom-bordered,
    .row-btngroup-top {
        border: 1px solid tint($lims-bgdark, 85%) !important;
    }

    .ReactTags__selected span.ReactTags__tag,
    .css-1rhbuit-multiValue,
    .table.is-hoverable.is-striped tbody tr.stb-info-row,
    .table.is-hoverable.is-striped tbody tr.stb-info-row:not(.is-selected):hover,
    .hd-bg,
    .row-btngroup-top {
        background: tint($lims-bgdark, 90%) !important;
    }

    .table.is-striped tbody tr:not(.is-selected):nth-child(2n),
    .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
        background-color: tint($lims-bgdark, 95%) !important;
    }

    .table:not(.inner-table) thead {
        background: tint($lims-navdarker, 30%) !important;
    }

    .inner-table thead {
        background: tint($lims-bgdark, 85%) !important;
        border: 1px solid tint($lims-bgdark, 80%) !important;
    }

    .react-tabs__tab {
        background: tint($lims-bgdark, 90%);

        .tab-count {
            background: tint($lims-bgdark, 65%);
        }
    }

    .react-tabs__tab-list {
        border-bottom: 1px solid tint($lims-bgdark, 80%) !important;
    }

    .react-tabs__tab--selected {
        background: tint($lims-bgdark, 40%) !important;
    }

    .nav-child-1 .c-icon::after {
        background: tint($lims-bgdark, 90%);
    }

    .blank-div {
        background: tint($lims-bgdark, 90%);
    }

    .sub-filter {
        background: tint($lims-bgdark, 95%);
    }

    .modal-card-foot,
    .modal-card-head {
        padding: 15px 20px !important;
        background-color: tint($lims-bgdark, 90%) !important;
        border-color: tint($lims-bgdark, 85%) !important;
    }

    .message.is-light .message-header {
        background-color: tint($lims-bgdark, 90%) !important;
        border: 1px solid tint($lims-bgdark, 85%) !important;
    }

    .breadcrumb {

        &::before,
        &::after {
            background-color: tint($lims-bgdark, 85%) !important;
        }

        li {
            background-color: tint($lims-bgdark, 85%) !important;

            &:first-child::before {
                background-color: tint($lims-bgdark, 85%) !important;
                box-shadow: 1px -1px 0 1px tint($lims-bgdark, 75%), 2px -2px 0 2px tint($lims-bgdark, 90%);
            }

            &::after {
                background-color: tint($lims-bgdark, 85%) !important;
                box-shadow: 1px -1px 0 1px tint($lims-bgdark, 75%), 2px -2px 0 2px tint($lims-bgdark, 90%);
            }

            &.is-active,
            &:hover {
                background-color: tint($lims-bgdark, 70%) !important;

                &::after {
                    background-color: tint($lims-bgdark, 70%) !important;
                }
            }
        }
    }
}

#root.lims-eln {

    .addLocation::before,
    .addLocation::after {
        color: $lims-eln-bgdark;
    }

    .custom-check input:checked~.checkmark,
    .custom-radio input:checked~.radiomark {
        background-color: $lims-eln-bgdark;
        border: 1px solid $lims-eln-bgdark;
    }

    .css-26l3qy-menu {

        .css-9gakcf-option {
            background: $lims-eln-bgdark !important;
        }

        .css-1n7v3ny-option {
            background: tint($lims-eln-bgdark, 90%) !important;
        }
    }

    .accordion {
        background: tint($lims-eln-bgdark, 90%) !important;
    }

    .accordion-panel,
    .custom-bordered,
    .row-btngroup-top {
        border: 1px solid tint($lims-eln-bgdark, 85%) !important;
    }

    .ReactTags__selected span.ReactTags__tag,
    .css-1rhbuit-multiValue,
    .table.is-hoverable.is-striped tbody tr.stb-info-row,
    .table.is-hoverable.is-striped tbody tr.stb-info-row:not(.is-selected):hover,
    .hd-bg,
    .row-btngroup-top {
        background: tint($lims-eln-bgdark, 90%) !important;
    }

    .table.is-striped tbody tr:not(.is-selected):nth-child(2n),
    .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
        background-color: tint($lims-eln-bgdark, 95%) !important;
    }

    .table:not(.inner-table) thead {
        background: tint($lims-eln-bgdark, 40%) !important;
    }

    .inner-table thead {
        background: tint($lims-eln-bgdark, 85%) !important;
        border: 1px solid tint($lims-eln-bgdark, 80%) !important;
    }

    .react-tabs__tab {
        background: tint($lims-eln-bgdark, 90%);

        .tab-count {
            background: tint($lims-eln-bgdark, 65%);
        }
    }

    .react-tabs__tab-list {
        border-bottom: 1px solid tint($lims-eln-bgdark, 80%) !important;
    }

    .react-tabs__tab--selected {
        background: tint($lims-eln-bgdark, 40%) !important;
    }

    .nav-child-1 .c-icon::after {
        background: tint($lims-eln-bgdark, 90%);
    }

    .blank-div {
        background: tint($lims-eln-bgdark, 90%);
    }

    .sub-filter {
        background: tint($lims-eln-bgdark, 95%);
    }

    .modal-card-foot,
    .modal-card-head {
        padding: 15px 20px !important;
        background-color: tint($lims-eln-bgdark, 90%) !important;
        border-color: tint($lims-eln-bgdark, 85%) !important;
    }

    .message.is-light .message-header {
        background-color: tint($lims-eln-bgdark, 90%) !important;
        border: 1px solid tint($lims-eln-bgdark, 85%) !important;
    }

    .breadcrumb {

        &::before,
        &::after {
            background-color: tint($lims-eln-bgdark, 90%) !important;
        }

        li {
            background-color: tint($lims-eln-bgdark, 90%) !important;

            &:first-child::before {
                background-color: tint($lims-eln-bgdark, 90%) !important;
                box-shadow: 1px -1px 0 1px tint($lims-eln-bgdark, 80%), 2px -2px 0 2px tint($lims-eln-bgdark, 95%);
            }

            &::after {
                background-color: tint($lims-eln-bgdark, 90%) !important;
                box-shadow: 1px -1px 0 1px tint($lims-eln-bgdark, 80%), 2px -2px 0 2px tint($lims-eln-bgdark, 95%);
            }

            &.is-active,
            &:hover {
                background-color: tint($lims-eln-bgdark, 80%) !important;

                &::after {
                    background-color: tint($lims-eln-bgdark, 80%) !important;
                }
            }
        }
    }
}

#root.eln {

    .addLocation::before,
    .addLocation::after {
        color: $eln-bgdark;
    }
    .custom-check input:checked~.checkmark,
    .custom-radio input:checked~.radiomark {
        background-color: $eln-bgdark;
        border: 1px solid $eln-bgdark;
    }

    .css-26l3qy-menu {

        .css-9gakcf-option {
            background: $eln-bgdark !important;
        }

        .css-1n7v3ny-option {
            background: tint($eln-bgdark, 90%) !important;
        }
    }

    .accordion {
        background: tint($eln-bgdark, 90%) !important;
    }

    .accordion-panel,
    .custom-bordered,
    .row-btngroup-top {
        border: 1px solid tint($eln-bgdark, 85%) !important;
    }

    .ReactTags__selected span.ReactTags__tag,
    .css-1rhbuit-multiValue,
    .table.is-hoverable.is-striped tbody tr.stb-info-row,
    .table.is-hoverable.is-striped tbody tr.stb-info-row:not(.is-selected):hover,
    .hd-bg,
    .row-btngroup-top {
        background: tint($eln-bgdark, 90%) !important;
    }

    .table.is-striped tbody tr:not(.is-selected):nth-child(2n),
    .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
        background-color: tint($eln-bgdark, 95%) !important;
    }

    .table:not(.inner-table) thead {
        background: tint($eln-bgdark, 40%) !important;
    }

    .inner-table thead {
        background: tint($eln-bgdark, 85%) !important;
        border: 1px solid tint($eln-bgdark, 80%) !important;
    }

    .react-tabs__tab {
        background: tint($eln-bgdark, 90%);

        .tab-count {
            background: tint($eln-bgdark, 65%);
        }
    }

    .react-tabs__tab-list {
        border-bottom: 1px solid tint($eln-bgdark, 80%) !important;
    }

    .react-tabs__tab--selected {
        background: tint($eln-bgdark, 40%) !important;
    }

    .nav-child-1 .c-icon::after {
        background: tint($eln-bgdark, 90%);
    }

    .blank-div {
        background: tint($eln-bgdark, 90%);
    }

    .sub-filter {
        background: tint($eln-bgdark, 95%);
    }

    .modal-card-foot,
    .modal-card-head {
        padding: 15px 20px !important;
        background-color: tint($eln-bgdark, 90%) !important;
        border-color: tint($eln-bgdark, 85%) !important;
    }

    .message.is-light .message-header {
        background-color: tint($eln-bgdark, 90%) !important;
        border: 1px solid tint($eln-bgdark, 85%) !important;
    }

    .breadcrumb {

        &::before,
        &::after {
            background-color: tint($eln-bgdark, 85%) !important;
        }

        li {
            background-color: tint($eln-bgdark, 85%) !important;

            &:first-child::before {
                background-color: tint($eln-bgdark, 85%) !important;
                box-shadow: 1px -1px 0 1px tint($eln-bgdark, 75%), 2px -2px 0 2px tint($eln-bgdark, 90%);
            }

            &::after {
                background-color: tint($eln-bgdark, 85%) !important;
                box-shadow: 1px -1px 0 1px tint($eln-bgdark, 75%), 2px -2px 0 2px tint($eln-bgdark, 90%);
            }

            &.is-active,
            &:hover {
                background-color: tint($eln-bgdark, 70%) !important;

                &::after {
                    background-color: tint($eln-bgdark, 70%) !important;
                }
            }
        }
    }
}