.navbar {
    &.lims-eln-nav {
        background: $lims-eln-gradient;
        background-color: $lims-eln-bgdark;
    }

    &.eln-nav {
        background: $eln-gradient;
        background-color: $eln-bgdark;
    }

    &.lims-nav {
        background: $lims-gradient;
        background-color: $lims-bgdark;
    }
}